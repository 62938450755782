*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'poppins',sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
html,body{
  scroll-behavior: smooth;
  overflow-x: hidden;
}
@tailwind base;
@tailwind components;
@tailwind utilities;